'use client';
const auth0 = require('auth0');

import {useEffect} from 'react';

const CorsFallback = () => {
  useEffect(() => {
    const auth0Client = new auth0.WebAuth({
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
      domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    });

    auth0Client.crossOriginVerification();
  }, []);

  // Return empty div as this is just a utility page
  return <div></div>;
};

export default CorsFallback;
